exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-decorative-art-index-js": () => import("./../../../src/pages/decorative-art/index.js" /* webpackChunkName: "component---src-pages-decorative-art-index-js" */),
  "component---src-pages-decorative-art-slug-js": () => import("./../../../src/pages/decorative-art/[...slug].js" /* webpackChunkName: "component---src-pages-decorative-art-slug-js" */),
  "component---src-pages-events-0-js": () => import("./../../../src/pages/events_0.js" /* webpackChunkName: "component---src-pages-events-0-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-option-1-js": () => import("./../../../src/pages/option-1.js" /* webpackChunkName: "component---src-pages-option-1-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

