import React from "react"

const defaultState = {
  bg: 'light',
  modal: 'off',
  sliderClick: false,
  album: null,
  nav_up: false,
  modalInfo: false,
  flipState: null,
  collectionSlug:null,
  toggleNavFlag: () => {},
  toggleNavFlag: () => {},
  toggleSliderClick: () => {},
  toggleModal: () => {},
  toggleModalTransition: () => {},
  toggleFlipState: () => {},
  animateScrollDown: () => {},
  toggleInfo: () => {},
  toggleDataSet: () => {},
  toggleShortCut: () => {},


}

const ThemeContext = React.createContext(defaultState)



class ThemeProvider extends React.Component {
  state = {
    bg: 'light',
    modal: 'off',
    modalTransition: false,
    modalInfo: false,
    album: null,
    ImageId: 0,
    nav_up: false,
    flipState: [],
    collectionSlug:null,
    sliderClick: true,
    productView: false,
    featuredSrc: '',
    initSrc: '',
    productViewCollection:'',
    productViewTitle:'',
    dataset: null,
    shortcut: false
  }

  toggleBg = (string) => {
    let bg = string
    this.setState({ bg })
  }
  toggleAlbum = (string) => {
    let album = string
    this.setState({ album })
  }
  toggleDataSet = (int) => {
    let dataset = int
    this.setState({ dataset })
  }
  toggleImageId = (int) => {
    let imageId = int
    this.setState({ imageId })
  }

  toggleNavFlag = (bool) => {
    let nav_up = bool
    this.setState({ nav_up })
  }
  toggleModal = (str) => {
    let modal = str
    this.setState({ modal })
  }
  toggleModalInfo = (str) => {
    let modalInfo = str
    this.setState({ modalInfo })
  }
  toggleModalTransition = (str) => {
    let modalTransition = str
    this.setState({ modalTransition })
  }
  toggleFlipState = (arr) => {
    let flipState = arr
    this.setState({ flipState })
  }
  toggleSliderClick = (bool) => {
    let sliderClick = bool
    this.setState({ sliderClick })
  }
  toggleProductView = (bool) => {
    let productView = bool
    this.setState({ productView })
  }
  toggleFeaturedSrc = (string) => {
    let featuredSrc = string
    this.setState({ featuredSrc })
  }
  toggleCollectionSlug = (string) => {
    let slug = string
    this.setState({ slug })
  }
  toggleInitSrc = (string) => {
    let initSrc = string
    this.setState({ initSrc })
  }
  toggleInfo = (obj) => {
    let productViewTitle = obj.title;
    let productViewCollection = obj.collection;
    let productViewDesc = obj.desc;
    this.setState({ productViewTitle: productViewTitle, productViewCollection: productViewCollection, productViewDesc})
  }
  toggleShortcut = (bool) => {
    let shortcut = bool
    this.setState({ shortcut })
  }

  animateScrollDown = (state) => {
    //console.log(this.state.modal);
  }

  componentDidMount() {


  }

  render() {
    const { children } = this.props
    const { bg, dataset, modal, modalInfo, collectionSlug, modalTransition, nav_up, album, ImageId, flipState,sliderClick, productView, featuredSrc, initSrc, productViewTitle, productViewCollection, productViewDesc, shortcut } = this.state
    return (
      <ThemeContext.Provider
        value={{
          bg,
          dataset,
          album,
          ImageId,
          modal,
          modalInfo,
          modalTransition,
          nav_up,
          flipState,
          collectionSlug,
          sliderClick,
          productView,
          featuredSrc,
          initSrc,
          productViewTitle,
          productViewCollection,
          productViewDesc,
          shortcut,
          toggleBg: this.toggleBg,
          toggleAlbum: this.toggleAlbum,
          toggleDataSet: this.toggleDataSet,
          toggleImageId: this.toggleImageId,
          toggleNavFlag: this.toggleNavFlag,
          toggleModal: this.toggleModal,
          toggleModalInfo: this.toggleModalInfo,
          toggleModalTransition: this.toggleModalTransition,
          toggleFlipState: this.toggleFlipState,
          toggleCollectionSlug: this.toggleCollectionSlug,
          toggleProductView: this.toggleProductView,
          toggleSliderClick: this.toggleSliderClick,
          toggleFeaturedSrc: this.toggleFeaturedSrc,
          toggleInitSrc: this.toggleInitSrc,
          toggleInfo: this.toggleInfo,
          toggleShortcut: this.toggleShortcut,
          animateScrollDown: this.animateScrollDown
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}

export default ThemeContext

export { ThemeProvider }
